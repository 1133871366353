.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Wrapper that contains both the navbar and the content */
.wrapper {
  display: flex;
  transition: margin-left 0.5s ease;
}

/* Container for the main content */
.content-container {
  width: 100%;
  transition: margin-left 0.5s ease;
}

/* Shifting the main content to the right when navbar is open */
.content-container.shifted {
  margin-left: 250px;
}

.signout-btn-wrapper {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}

/* Table Row Colour according to their type */
.ant-table-row.ordered-row {
  background-color: #f2f2f2 !important;
}

.ant-table-row.treatment-row {
  background-color: white !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.button-container {
  display: flex;
  gap: 1rem;

  padding-bottom: 1rem;
}

/* App.css */
.app-container {
  font-family: "Raleway", sans-serif;
  position: relative;

  /* overflow: auto; */
}

.background-color {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../src/Assets/view-old-tree-lake-with-snow-covered-mountains-cloudy-day.jpg");
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.content-container {
  position: relative;
  z-index: 1;
}
.buttonsarray {
  display: flex;

  gap: 0.1rem;
}

.selectWithValue {
  background-color: #a1c4fd; /* Replace with your desired color */
  color: white;
  /* Add other styling as needed */
}

.Date-Container-Fix-Header {
  left: 0;
  background-color: #eeeeee;
  z-index: 2000 !important;
  min-width: 100px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  @media screen and (min-width: 600px) {
    position: sticky;
  }
}

/* YourComponent.css */

.hide-on-sm-screen {
  display: block; /* Or any other display property for large screens */
}

@media (max-width: 600px) {
  .hide-on-sm-screen {
    display: none; /* Hide on small screens */
  }
}

.Date-Container-Fix {
  left: 0;
  background-color: #ffffff;
  z-index: 1000 !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  @media screen and (min-width: 600px) {
    position: sticky;
  }
}

.MuiTableCell-root {
  text-align: center !important;
}

/* Nav Bar Styling */

/* Basic Layout */

/* Interactivity */
.navbar a:hover {
  background-color: #383b40; /* Slightly lighter than the navbar bg on hover */
  /* Pure white on hover */
  letter-spacing: 2px;
  transition: letter-spacing 0.3s ease-in;
}

.button-container {
  display: flex;
  border-radius: 6px;
  letter-spacing: 1px;

  button {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    letter-spacing: 1px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* smaller shadow when active for more depth */
  }
}

.font-type {
  font-family: "Raleway", sans-serif;
}

.btn-color {
  background-color: #007bff !important;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease-in-out; /* generalizes the transition for all properties */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: auto;
}

.btn-color-red {
  background-color: red !important;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease-in-out; /* generalizes the transition for all properties */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Button with no background color */
.btn-color-NoBg {
  background-color: transparent !important;
  color: black;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease-in-out; /* generalizes the transition for all properties */
}

.btn-color-BorderOnly {
  background-color: transparent !important;
  color: #007bff !important;
  border: 1px solid #007bff !important;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease-in-out; /* generalizes the transition for all properties */
}

.btn-color-NoBg:hover {
  text-decoration: underline;
  background-color: transparent !important;
  color: #007bff;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease-in-out; /* generalizes the transition for all properties */
}

.green-btn {
  background-color: rgb(14, 192, 14) !important;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease-in-out; /* generalizes the transition for all properties */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: auto;
}

.btn-color:hover {
  color: white !important;
  background-color: #1976d2 !important;
}

/* Nav Bar menu button styling */

.menu-button {
  position: absolute;
  top: 10px;
  left: 2%;
  z-index: 100;
  border: none;
  color: #e5e5e5;
  background-color: #007bff;
  padding: 6px 8px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out; /* generalizes the transition for all properties */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* soft shadow for a slight elevation effect */
}

.menu-button:hover {
  background: #1976d2;
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1); /* slightly larger shadow on hover for depth */
}

.menu-button:active {
  transform: scale(1); /* reset scale to give a pressed-in effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* smaller shadow when active for more depth */
}

.ant-picker-body {
  width: 240px !important;
  font-size: 15px;
}
.ant-picker-content {
  height: 210px !important;
}

.content-container,
.wrapper,
.content-container,
.loginCotainerLoginView {
  height: 100%;
}

.loginCotainerLoginView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .wrapper{
  background-image: url("../src/Assets/view-old-tree-lake-with-snow-covered-mountains-cloudy-day.jpg"); 
  background-size: cover;
  background-position: center;
  z-index: -1;
  min-height: 100vh;
} */
.todayview-bg {
  background-color: white;
}

.ant-picker-ok button {
  color: white;
  background-color: #3b82f6;
}

.ant-picker-time-panel-cell-disabled {
  display: none;
}
.fadeIn {
  animation: fadeInAnimation 0.5s ease-in-out forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateX(-5px); /* Change from translateY to translateX */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Change from translateY to translateX */
  }
}

.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background-color: rgb(217, 233, 252) !important;
}


.loader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}


.lab-tracker-order-type-row {
  background-color: #fdefdf !important;
}

.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
  height: 100%;
}





/* AG Grid Styling for New Treatment View */

.ag-header-cell {
  height: auto; 
  padding: 10px 5px;
}
.ag-header-group {
  height: auto;
  padding: 10px 5px;
}
.rotate-header {
  writing-mode: vertical-rl;
  text-align: left; 
  transform: rotate(180deg); 
  white-space: normal; 
  word-break: break-word; 
  padding: 5px; 
  overflow: visible; 
  height: auto; 
}

.rotate-header .ag-header-group-cell-label {
  white-space: normal;
  word-break: break-word;
  padding: 5px;
  overflow: visible;
  height: auto;
  height: 140px;
}


/* quill editor styling for EMR  */
#quill-editor-section .ql-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#quill-editor-section .ql-editor {
  flex: 1;
  overflow-y: auto;
}




/* EMR page tabs styling */
.tabs-container .ant-tabs-nav {
  margin-bottom: 16px;
  border: none !important;
}

.tabs-container .ant-tabs-nav .ant-tabs-tab {
  margin-right: 10px; 
  padding: 12px 24px; 
  border: none !important;
  background-color: #e0e0e0;  
}

.tabs-container .ant-tabs-nav .ant-tabs-tab-active {
  background:#111828 !important;
  color: white !important;
  border: none !important;
}

.tabs-container .ant-tabs-nav .ant-tabs-tab-with-remove {
  color: black !important;
  fill: black !important;
}

.tabs-container .ant-tabs-nav .ant-tabs-tab-with-remove:hover,.tabs-container .ant-tabs-nav .ant-tabs-tab-with-remove:hover svg {
  transition: all;
  transition-duration: 150ms;
  color: white !important;
  fill: white !important;  
}

.tabs-container .ant-tabs-nav .ant-tabs-tab-with-remove svg {
  color: black !important;
  fill: black !important;
}


.tabs-container .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {  
  color: white !important;
  border: none !important;
}

.tabs-container .ant-tabs-nav .ant-tabs-tab:hover {
  background-color:#3d3d3d !important;
  color: white;
  border: none !important;
  transition: none;
}

.tabs-container .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn:active {
  background-color:#3d3d3d !important;
  color: white;
  border: none !important;
  transition: none;
  fill: white !important;
}

.tabs-container .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-remove svg {
  color: white !important;
  fill: white !important;
}

.tabs-container .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-remove:hover svg {
  color: #ffffff !important;
  fill: #ffffff !important;
}

.tabs-container .ant-tabs-nav .ant-tabs-tab-disabled {
  border: none !important;
  color: #bfbfbf; 
}

.tabs-container .ant-tabs-content {
  padding: 16px; 
  border: 1px solid #e8e8e8;
  border-radius: 4px; 
  background-color: #ffffff;
}
