/* Base navbar styles */
.navbar {
  width: 290px;
  height: 100vh;
  background: var(--darkBlackBlue, #292d32);
  color: white;
  position: fixed;
  transition: all 0.3s ease;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
}

.navbar.half-closed {
  width: 85px;
}

/* Navbar items container */
.Navbar-items {
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

.Navbar-items::-webkit-scrollbar {
  width: 4px;
}

.Navbar-items::-webkit-scrollbar-track {
  background: transparent;
}

.Navbar-items::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.navbar-items-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1rem 0;
}

/* Toggle button styles */
.toggle-button {
  position: absolute;
  left: 100%;
  top: 13%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 60px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  filter: drop-shadow(0px 4px 18px rgba(0, 0, 0, 0.13));
  z-index: 999;
  transition: all 0.3s ease;
}

.toggle-button:hover {
  filter: drop-shadow(0px 4px 18px rgba(0, 0, 0, 0.2));
}

.toggle-button svg {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
  color: #292d32;
}

/* Logo styles */
.navbar .my-9 {
  padding: 1.5rem;
  text-align: center;
}

.navbar .my-9 img {
  max-width: 180px;
  height: auto;
}

/* Navigation links */
.navbar-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 0.5rem;
}

.active-dive {
  visibility: hidden;
  width: 4px;
  min-height: 48px;
  border-radius: 0 4px 4px 0;
}

.active-dive.active-bar {
  visibility: visible;
  background: linear-gradient(130deg, #1fa0cb -41.72%, #5fbbda 24.86%, #70c186 70.13%, #8cd663 120.73%);
}

.navbarlink-cotent-flex {
  display: flex;
  align-items: center;
  padding: 0.75rem 0.5rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  text-decoration: none;
}

.navbar-link-active-flex {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0.75rem;
}

/* Icon styles */
.navbar-link-active-flex svg {
  width: 24px;
  height: 24px;
  transition: all 0.2s ease;
}

.filter-drop-shadow-glow {
  filter: drop-shadow(0 0 8px rgba(31, 160, 203, 0.4));
}

/* Link text styles */
.navbarlink-cotent-flex p {
  color: var(--bluishGray, #a7b2c1);
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.2px;
  margin: 0;
  white-space: nowrap;
  transition: color 0.2s ease;
}

.active p {
  color: var(--white, #fff);
}

/* Development menu section */
.dev-menu-section {
  margin-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 0.5rem;
}

.dev-menu-toggle {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  color: #a7b2c1;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  background: transparent;
  border: none;
  cursor: pointer;
}

.dev-menu-toggle:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
}

.dev-menu-toggle span {
  color: #a7b2c1;
  transition: color 0.2s ease;
}

.dev-menu-toggle:hover span {
  color: #fff;
}

.dev-menu-toggle svg {
  transition: transform 0.2s ease;
}

.dev-menu-items {
  padding-left: 0.5rem;
}

.dev-menu-items .navbarlink-cotent-flex {
  padding-left: 1.25rem;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.dev-menu-items .navbarlink-cotent-flex:hover {
  opacity: 1;
}

/* Active states */
.navbarlink-cotent-flex.active {
  background: linear-gradient(
    130deg,
    rgba(31, 160, 203, 0.15) -41.72%,
    rgba(95, 187, 218, 0.15) 24.86%,
    rgba(112, 193, 134, 0.15) 70.13%,
    rgba(140, 214, 99, 0.15) 120.73%
  );
}

/* Sign out button */
.navbar-signout-btn {
  padding: 1rem;
  margin-top: auto;
}

.signout-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  background: linear-gradient(96deg, #1fa0cb -97.3%, #5fbbda 12.1%, #70c186 86.49%, #8cd663 169.63%);
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  color: white;
}

.signout-button:hover {
  opacity: 0.9;
}

.signout-button span {
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

/* Collapsed state styles */
.navbar.half-closed .navbar-link-active-flex {
  padding-left: 0.75rem;
  justify-content: center;
}

.navbar.half-closed .signout-button {
  padding: 0.75rem;
  background: transparent;
}

.navbar.half-closed .signout-button span,
.navbar.half-closed .navbarlink-cotent-flex p,
.navbar.half-closed .dev-menu-toggle span:last-child {
  display: none;
}

/* Content container adjustments */
.content-container.navbar-open {
  margin-left: 290px;
  transition: margin-left 0.3s ease;
}

.content-container.navbar-closed {
  margin-left: 85px;
  transition: margin-left 0.3s ease;
}

/* Hover effects */
.navbarlink-cotent-flex:hover:not(.active) {
  background: rgba(255, 255, 255, 0.05);
}

/* Responsive styles */
@media screen and (max-width: 1200px) {
  .navbar {
    width: 260px;
  }
  
  .navbar.half-closed {
    width: 75px;
  }
  
  .content-container.navbar-open {
    margin-left: 260px;
  }
  
  .content-container.navbar-closed {
    margin-left: 75px;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    width: 240px;
  }
  
  .navbar.half-closed {
    width: 0;
    transform: translateX(-100%);
  }
  
  .toggle-button {
    width: 45px;
    height: 45px;
    top: 13%;
  }
  
  .toggle-button svg {
    width: 20px;
    height: 20px;
  }
  
  .content-container.navbar-open {
    margin-left: 240px;
  }
  
  .content-container.navbar-closed {
    margin-left: 0;
  }
  
  .navbarlink-cotent-flex p {
    font-size: 13px;
  }
}

@media screen and (max-width: 480px) {
  .navbar {
    width: 100%;
    transform: translateX(-100%);
  }
  
  .navbar.open {
    transform: translateX(0);
  }
  
  .content-container.navbar-open,
  .content-container.navbar-closed {
    margin-left: 0;
  }
  
  .toggle-button {
    top: 20px;
    right: -45px;
    left: auto;
  }
  
  .navbar.open .toggle-button {
    right: 15px;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dev-menu-items {
  animation: fadeIn 0.3s ease;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .toggle-button {
    background-color: #292d32;
  }
  
  .toggle-button svg {
    color: #fff;
  }
}

/* Utility classes */
.text-gray-400 {
  color: #a7b2c1;
}

.text-gray-300 {
  color: #cbd5e1;
}

.text-blue-400 {
  color: #1fa0cb;
}

.hover\:text-gray-200:hover {
  color: #e2e8f0;
}

.transition-all {
  transition: all 0.2s ease;
}

.duration-200 {
  transition-duration: 200ms;
}

.transform {
  transform-origin: center;
}

.rotate-90 {
  transform: rotate(90deg);
}